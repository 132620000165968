import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import axios from 'axios';
import { useDispatch } from 'react-redux';

// Component Imports
import InputSelect from './InputSelect';

const AddressLookup = React.forwardRef(
  ({ id, searchLabel, value, required, disabled, onChange, hasError, actionName }, ref) => {
    const [state, setState] = useState({
      isValid: false,
      licenseKey: 'MH45-PE94-XB79-CD33',
      addressList: [],
    });

    // React Redux hooks
    const dispatch = useDispatch();

    const classes = classNames({
      'b-form-control': true,
      'is-invalid': hasError,
      'is-valid': state.isValid,
    });

    const handleBlur = () => {
      if (!hasError) {
        setState({ ...state, isValid: true });
      }
    };

    const getAddressList = (addressID) => {
      axios
        .get(
          `https://services.postcodeanywhere.co.uk/Capture/Interactive/Find/v1.10/json3.ws?Key=${state.licenseKey}&Text=${value}&Container=${addressID}&IsMiddleware=false&Countries=GBR&Language=en-gb`,
        )
        .then((response) => {
          if (response.data.Items[0].Error) {
            // console.log(response.data.Items[0].Error);
          } else {
            setState({ ...state, addressList: response.data.Items });
          }
        });
    };

    const searchByPostcode = () => {
      axios
        .get(
          `https://services.postcodeanywhere.co.uk/Capture/Interactive/Find/v1.10/json3.ws?Key=${state.licenseKey}&Text=${value}&IsMiddleware=false&Countries=GBR&Language=en-gb`,
        )
        .then((response) => {
          if (response.data.Items[0].Error) {
            // console.log(response.data.Items[0].Error);
          } else {
            getAddressList(response.data.Items[0].Id);
          }
        });
    };

    const handleClick = (event) => {
      event.preventDefault();

      searchByPostcode();
    };

    const handleAddressSelect = (event) => {
      const fullAddress = event.target.value;

      const addressArray = fullAddress.split(', ');

      dispatch({ type: `SET_${actionName}_ADDRESS_LINE_1`, payload: addressArray[0] });
      dispatch({ type: `SET_${actionName}_ADDRESS_LINE_2`, payload: addressArray.length > 3 ? addressArray[1] : '' });
      dispatch({
        type: `SET_${actionName}_ADDRESS_LINE_3`,
        payload: addressArray.length > 4 ? addressArray.slice(2, -2).join(', ') : '',
      });
      dispatch({ type: `SET_${actionName}_TOWN`, payload: addressArray[addressArray.length - 2] });
      dispatch({ type: `SET_${actionName}_POSTCODE`, payload: addressArray[addressArray.length - 1] });
    };

    return (
      <>
        <div className="b-form-group">
          <div className="input-group">
            <input
              className={classes}
              type="text"
              name={id}
              id={id}
              placeholder={searchLabel}
              value={value}
              required={required}
              disabled={disabled}
              onChange={onChange}
              ref={ref}
              aria-invalid={hasError ? true : undefined}
              onBlur={handleBlur}
            />
            <label className="sr-only" htmlFor={id}>
              {searchLabel}
            </label>

            <button
              className="input-group-append postcodelookup"
              onClick={handleClick}
              style={{ border: 0, padding: 0 }}
            >
              <span className="input-group-text" style={{ height: '100%' }}>
                Find Address
              </span>
            </button>
          </div>
        </div>

        {state.addressList.length > 0 && (
          <div className="b-form-group">
            <InputSelect labelText="Select address" id={`address-lookup-list-${id}`} onChange={handleAddressSelect}>
              <InputSelect.Option value="">Select address</InputSelect.Option>
              {state.addressList.map((address) => (
                <InputSelect.Option value={`${address.Text}, ${address.Description}`} key={address.Text}>
                  {address.Text},{address.Description}
                </InputSelect.Option>
              ))}
            </InputSelect>
          </div>
        )}
      </>
    );
  },
);

AddressLookup.propTypes = {
  id: PropTypes.string.isRequired,
  searchLabel: PropTypes.string.isRequired,
  value: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  hasError: PropTypes.bool,
  actionName: PropTypes.string.isRequired,
};

AddressLookup.defaultProps = {
  value: '',
  required: false,
  disabled: false,
  onChange: () => {},
  hasError: false,
};

export default AddressLookup;
