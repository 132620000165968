import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const InputSelect = React.forwardRef(({ labelText, id, disabled, children, onChange, hasError, value }, ref) => {
  const [isValid, setIsValid] = useState(false);

  const classes = classNames({
    'b-form-select': true,
    'is-invalid': hasError,
    'is-valid': isValid,
  });

  const handleBlur = () => {
    if (!hasError) {
      setIsValid(true);
    }
  };

  return (
    <>
      <label className="sr-only" htmlFor={id}>
        {labelText}
      </label>
      <select
        className={classes}
        id={id}
        name={id}
        disabled={disabled}
        onChange={onChange}
        ref={ref}
        aria-invalid={hasError ? true : undefined}
        onBlur={handleBlur}
        value={value}
      >
        {children}
      </select>
    </>
  );
});

InputSelect.propTypes = {
  labelText: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
  onChange: PropTypes.func,
  hasError: PropTypes.bool,
  value: PropTypes.string,
};

InputSelect.defaultProps = {
  disabled: false,
  onChange: () => {},
  hasError: false,
  value: '',
};

InputSelect.Option = ({ children, value }) => {
  return <option value={value}>{children}</option>;
};

InputSelect.Option.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.string.isRequired,
};

export default InputSelect;
