import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const OptOut = React.forwardRef(({ on_dark }, ref) => {
  const componentClasses = classNames({
    'c-opt-out': true,
    'c-opt-out--inverted': on_dark,
  });

  const id = `contact_opt_out_${Date.now()}`;

  return (
    <div className={componentClasses}>
      <div className="custom-checkbox custom-checkbox-lg">
        <input className="custom-control-input" type="checkbox" id={id} name="contact_opt_out" value="true" ref={ref} />
        <label className="custom-control-label" htmlFor={id}>
          We will sometimes send you emails about our very latest offers and relevant news, in accordance with{' '}
          <a
            href="https://ico.org.uk/media/for-organisations/documents/1555/direct-marketing-guidance.pdf"
            target="_blank"
            rel="noreferrer"
          >
            ICO P&ECR
          </a>{' '}
          soft opt in rules & our{' '}
          <a href="/privacy-policy" target="_blank">
            privacy policy
          </a>
          . Simply tick the box if you do not wish to receive them.
        </label>
      </div>
    </div>
  );
});

OptOut.propTypes = {
  on_dark: PropTypes.bool,
};

OptOut.defaultProps = {
  on_dark: false,
};

export default OptOut;
