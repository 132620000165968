import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

// Component Imports
import InputSelect from './InputSelect';

const DateSelector = React.forwardRef(({ groupText, required, disabled, dispatchAction, id, hasError, value }, ref) => {
  const [state, setState] = useState({
    days: [],
    months: [],
    years: [],
    selectedDay: '',
    selectedMonth: '',
    selectedYear: '',
  });

  // React Redux hooks
  const dispatch = useDispatch();

  const populateSelectOptions = () => {
    const updatedDays = [];
    const updatedMonths = [];
    const updatedYears = [];

    const currentYear = new Date().getFullYear();

    for (let i = 0; i < 31; ++i) {
      updatedDays.push(
        <InputSelect.Option value={`${i + 1 < 10 ? `0${i + 1}` : i + 1}`} key={`day-${i}`}>
          {i + 1 < 10 ? `0${i + 1}` : i + 1}
        </InputSelect.Option>,
      );
    }

    for (let i = 0; i < 12; ++i) {
      updatedMonths.push(
        <InputSelect.Option value={`${i + 1 < 10 ? `0${i + 1}` : i + 1}`} key={`month-${i}`}>
          {i + 1 < 10 ? `0${i + 1}` : i + 1}
        </InputSelect.Option>,
      );
    }

    for (let i = 0; i < 80; ++i) {
      updatedYears.push(
        <InputSelect.Option value={`${currentYear - i}`} key={`year-${i}`}>
          {currentYear - i}
        </InputSelect.Option>,
      );
    }

    setState({ ...state, days: updatedDays, months: updatedMonths, years: updatedYears });
  };

  const handleDispatch = (payload) => {
    dispatch({
      type: dispatchAction,
      payload,
    });
  };

  const dispatchDate = (event, type) => {
    const targetType = type;

    if (targetType === 'day') {
      setState({ ...state, selectedDay: event.target.value });
      handleDispatch(`${event.target.value}-${state.selectedMonth}-${state.selectedYear}`);
    } else if (targetType === 'month') {
      setState({ ...state, selectedMonth: event.target.value });
      handleDispatch(`${state.selectedDay}-${event.target.value}-${state.selectedYear}`);
    } else if (targetType === 'year') {
      setState({ ...state, selectedYear: event.target.value });
      handleDispatch(`${state.selectedDay}-${state.selectedMonth}-${event.target.value}`);
    }
  };

  useEffect(() => {
    populateSelectOptions();
  }, []);

  return (
    <div className="b-form-group row row-nomargin d-flex align-items-center">
      <span className="col-xl-12 col-left-label-padding col-no-right-padding md-font-size-normal sm-font-size-normal">
        {groupText}
        {required ? ' *' : ''}
      </span>

      <div className="col-xl-4 col-no-right-padding">
        <InputSelect
          labelText="Select day (format DD)"
          id={`${id}-day`}
          disabled={disabled}
          onChange={(event) => dispatchDate(event, 'day')}
          ref={ref}
          hasError={!!hasError[`${id}-day`]}
          value={value && value !== '' ? value.split('-')[0] : undefined}
        >
          <InputSelect.Option value="">DD</InputSelect.Option>
          {state.days}
        </InputSelect>
      </div>

      <div className="col-xl-4 col-no-right-padding">
        <InputSelect
          labelText="Select month (format MM)"
          id={`${id}-month`}
          disabled={disabled}
          onChange={(event) => dispatchDate(event, 'month')}
          ref={ref}
          hasError={!!hasError[`${id}-month`]}
          value={value && value !== '' ? value.split('-')[1] : undefined}
        >
          <InputSelect.Option value="">MM</InputSelect.Option>
          {state.months}
        </InputSelect>
      </div>

      <div className="col-xl-4 col-no-right-padding">
        <InputSelect
          labelText="Select year (format YYYY)"
          id={`${id}-year`}
          disabled={disabled}
          onChange={(event) => dispatchDate(event, 'year')}
          ref={ref}
          hasError={!!hasError[`${id}-year`]}
          value={value && value !== '' ? value.split('-')[2] : undefined}
        >
          <InputSelect.Option value="">YYYY</InputSelect.Option>
          {state.years}
        </InputSelect>
      </div>
    </div>
  );
});

DateSelector.propTypes = {
  groupText: PropTypes.string.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  dispatchAction: PropTypes.string,
  id: PropTypes.string.isRequired,
  hasError: PropTypes.shape({}),
  value: PropTypes.string,
};

DateSelector.defaultProps = {
  required: false,
  disabled: false,
  dispatchAction: null,
  hasError: {},
  value: '',
};

export default DateSelector;
