import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const EnquireNowField = React.forwardRef(
  (
    {
      type,
      id,
      labelText,
      onChange,
      value,
      hasError,
      errorMessage,
      rows,
      variant,
      placeholder,
      options,
      className,
      style,
    },
    ref,
  ) => {
    const [isValid, setIsValid] = useState(false);

    const handleBlur = () => {
      if (!hasError) {
        setIsValid(true);
      }
    };

    const inputClasses = classNames({
      'b-form-control': true,
      'is-invalid': hasError,
      'is-valid': !hasError && isValid,
    });

    const selectClasses = classNames({
      'b-form-select': true,
      'is-invalid': hasError,
      'is-valid': !hasError && isValid,
    });

    const Tag = variant || 'input';

    return (
      <div className={`b-form-group col-12 col-sm-6 ${className}`}>
        <label className="b-form-label sr-only" htmlFor={id}>
          {labelText}
        </label>

        {variant !== 'select' ? (
          <Tag
            className={inputClasses}
            type={type}
            name={id}
            id={id}
            value={value}
            onChange={onChange}
            ref={ref}
            aria-invalid={hasError ? true : undefined}
            onBlur={handleBlur}
            rows={rows}
            placeholder={placeholder}
            style={style}
          />
        ) : (
          <select
            className={selectClasses}
            id={id}
            name={id}
            value={value}
            onChange={onChange}
            ref={ref}
            aria-invalid={hasError ? true : undefined}
            onBlur={handleBlur}
          >
            {!!options &&
              options.map((option) => (
                <option value={option.value} key={option.value}>
                  {option.text}
                </option>
              ))}
          </select>
        )}

        <div className="invalid-feedback">{errorMessage}</div>
      </div>
    );
  },
);

EnquireNowField.propTypes = {
  type: PropTypes.string,
  id: PropTypes.string.isRequired,
  labelText: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  rows: PropTypes.string,
  variant: PropTypes.oneOf(['input', 'textarea', 'select']),
  placeholder: PropTypes.string,
  className: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
};

EnquireNowField.defaultProps = {
  type: 'text',
  hasError: false,
  rows: '',
  errorMessage: 'Please check and enter it again.',
  variant: 'input',
  placeholder: '',
  options: [],
  className: '',
  style: null,
};

export default EnquireNowField;
