import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

// Component Imports
import InputSelect from './InputSelect';

const TimeSelector = React.forwardRef(
  ({ id, required, disabled, labelText, yearsDispatch, monthsDispatch, hasError, value, onChange }, ref) => {
    const [state, setState] = useState({
      years: [],
      months: [],
      selectedYears: '0',
      selectedMonths: '0',
    });

    // React Redux hooks
    const dispatch = useDispatch();

    const populateSelectOptions = () => {
      const updatedYears = [];
      const updatedMonths = [];

      for (let i = 0; i < 26; ++i) {
        updatedYears.push(
          <InputSelect.Option value={`${i}`} key={`year-${id}-${i}`}>
            {i} Year
            {i !== 1 ? 's' : ''}
          </InputSelect.Option>,
        );
      }

      for (let i = 0; i < 12; ++i) {
        updatedMonths.push(
          <InputSelect.Option value={`${i}`} key={`month-${id}-${i}`}>
            {i} Month
            {i !== 1 ? 's' : ''}
          </InputSelect.Option>,
        );
      }

      setState({ ...state, years: updatedYears, months: updatedMonths });
    };

    const handleDispatch = (action, payload) => {
      dispatch({
        type: action,
        payload,
      });
    };

    const dispatchTime = (event, type) => {
      if (type === 'years') {
        setState({ ...state, selectedYears: event.target.value });
        handleDispatch(yearsDispatch, event.target.value);
      } else if (type === 'months') {
        setState({ ...state, selectedMonths: event.target.value });
        handleDispatch(monthsDispatch, event.target.value);
      }
    };

    useEffect(() => {
      populateSelectOptions();
    }, []);

    return (
      <div className="b-form-group row row-nomargin d-flex align-items-center">
        <span className="col-xl-12 col-left-label-padding col-no-right-padding md-font-size-normal sm-font-size-normal">
          {labelText}
          {required ? ' *' : ''}
        </span>

        <div className="col-xl-6 col-no-right-padding">
          <InputSelect
            labelText="Years"
            id={`years-at-${id}`}
            disabled={disabled}
            onChange={(event) => {
              dispatchTime(event, 'years');
              onChange && onChange(event);
            }}
            ref={ref}
            hasError={hasError ? !!hasError[`years-at-${id}`] : undefined}
            value={value ? value[0] : undefined}
          >
            <InputSelect.Option value="">Years</InputSelect.Option>
            {state.years}
          </InputSelect>
        </div>

        <div className="col-xl-6 col-no-right-padding">
          <InputSelect
            labelText="Months"
            id={`months-at-${id}`}
            disabled={disabled}
            onChange={(event) => dispatchTime(event, 'months')}
            ref={ref}
            hasError={hasError ? !!hasError[`months-at-${id}`] : undefined}
            value={value ? value[1] : undefined}
          >
            <InputSelect.Option value="">Months</InputSelect.Option>
            {state.months}
          </InputSelect>
        </div>
      </div>
    );
  },
);

TimeSelector.propTypes = {
  id: PropTypes.string.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  labelText: PropTypes.string.isRequired,
  yearsDispatch: PropTypes.string,
  monthsDispatch: PropTypes.string,
  hasError: PropTypes.shape({}),
  value: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  onChange: PropTypes.func,
};

TimeSelector.defaultProps = {
  required: false,
  disabled: false,
  yearsDispatch: '',
  monthsDispatch: '',
  hasError: null,
  value: [],
  onChange: null,
};

export default TimeSelector;
